<template>
  <div>
    <div class="bg-warehouse">
      <v-container
        class="v-container-fluid"
        fluid
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <div>
              <v-btn
                @click="$router.push({ name: 'Warehouse' })"
                text
                class="pl-0"
              >
                <v-icon
                  class="icon-return"
                  size="15px"
                >
                  mdi-arrow-left
                </v-icon>
                <span class="text-return mon-regular">{{ texts.warehouse.addWarehouse.buttonReturn }}</span>
              </v-btn>
            </div>
            <p class="text-title mon-bold mt-5">{{ texts.warehouse.addWarehouse.textTitle }}</p>
            <template>
              <v-tabs
                v-model="$store.state.tabWarehouse"
                background-color="transparent"
              >
                <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
                <v-tab class="tabs-profile mon-regular">{{ texts.warehouse.addWarehouse.generalInformation }}</v-tab>
                <!-- <v-tab class="tabs-profile mon-regular">{{ texts.warehouse.addWarehouse.attachments }}</v-tab>
                <v-tab class="tabs-profile mon-regular">{{ texts.warehouse.addWarehouse.images }}</v-tab> -->
              </v-tabs>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "AddWarehouseSearchbarLayout",
  data() {
    return {
      //VARIABLES
      tabWarehouse: null,
    };
  },
  beforeMount() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>

<style scoped>
.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
  color: #ffffff;
}

.separator-line-tab {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #ffffff !important;
  text-transform: initial;
  opacity: 1 !important;
}

.v-tab {
  font-family: "montserrat-bold", sans-serif;
  color: #ffffff !important;
  text-transform: initial;
  opacity: 0.5;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.bg-warehouse {
  background-color: currentcolor !important;
  background: url("../../../assets/images/bg-warehouse.png");
  /* height: 246px; */
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: drop-shadow(0px 0px 0px black);
  display: flex;
  align-items: flex-end;
}
</style>